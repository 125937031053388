<template>
  <div id="bannerView">
    <!-- banner -->
    <div class="swiper ban_swiper">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <a href="https://mp.weixin.qq.com/s/YsC5VD85uoDB0tkt6FSF2w" target="_blank">
            <img src="../../assets/image/pc/banner/121.jpg" alt="" />
          </a>
        </div>
        <div class="swiper-slide">
          <a href="https://mp.weixin.qq.com/s/gdfdAOYLr-Z6jHe1M6K6Pg" target="_blank">
            <img src="../../assets/image/pc/banner/hzb.png" alt="" />
          </a>
        </div>
        <div class="swiper-slide">
          <a href="https://mp.weixin.qq.com/s/Gz53m3v4qcSBVigunkGpYA" target="_blank">
            <img src="../../assets/image/pc/banner/hz555.jpg" alt="" />
          </a>
        </div>
        <div class="swiper-slide">
          <a href="https://mp.weixin.qq.com/s/abzYupDefKAkLi9oBlRZtw" target="_blank">
            <img src="../../assets/image/pc/banner/new19.jpg" alt="" />
          </a>
        </div>
        <div class="swiper-slide">
          <a href="https://mp.weixin.qq.com/s/SfqiCnSMZ8mvNaXA3P6_Mw" target="_blank">
            <img src="../../assets/image/pc/banner/1897.jpg" alt="" />
          </a>
        </div>
        <div class="swiper-slide">
          <a href="https://mp.weixin.qq.com/s/l1F93_MoYNPWv4o-evaeJA" target="_blank">
            <img src="../../assets/image/pc/banner/banner11.jpg" alt="" />
          </a>
        </div>
      </div>
      <div class="swiper-pagination2"></div>
    </div>
    <!-- 下载 -->
    <div class="download_box">
      <div class="ios_box" @click="iosTo()">
        <div class="ios_img">
          <img src="../../assets/image/icon/16.png" alt="" />
        </div>
        <div class="ios_text">
          <p class="ios_xz">下载iOS版本</p>
          <!-- <p class="ios_jqqd">(敬请期待...)</p> -->
        </div>
      </div>
      <div class="az_box" @click="azTo()">
        <div class="az_img">
          <img src="../../assets/image/icon/17.png" alt="" />
        </div>
        <div class="az_text">下载Android版本</div>
      </div>
      <div class="ios_box" @click="PCTo">
        <div class="ios_img">
          <img src="../../assets/image/icon/pc.png" alt
            style="width: 0.2rem /* 41/192 */; height: 0.2rem; /* 46/16 */" />
        </div>
        <div class="ios_text">
          <p class="ios_xz">下载电脑版本</p>
        </div>
      </div>
    </div>
    <div class="toast" v-if="isToast">即将震撼来袭 敬请期待</div>
  </div>
</template>

<script>
import { downloadA } from "@/utils/download";
export default {
  data() {
    return {
      pcSwiper: null,
      isToast: false,
    };
  },
  created() {

  },
  mounted() {
    this.homeSwiper();
  },
  methods: {
    azTo() {
      window.location.href = ANDROID_URL;
    },
    iosTo() {
      window.open(IOS_URL, '_blank');
    },
    PCTo() {
      downloadA(PC_URL, '_self')
      // this.isToast = true;
      // setTimeout(() => {
      //   this.isToast = false;
      // }, 2000);
    },
    homeSwiper() {
      //banner轮播图
      this.pcSwiper = new Swiper(".ban_swiper", {
        // spaceBetween: 30,
        // centeredSlides: true,

        autoplay: {
          delay: 4000,
          disableOnInteraction: true,
        },
        pagination: {
          el: ".swiper-pagination2",
          bulletClass: "my-bullet",
          clickable: true,
        },
        // loop: true,
        observer: true, //修改swiper自己或子元素时，自动初始化swiper，主要是这两行
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
      });

      this.pcSwiper.el.onmouseover = () => {
        this.pcSwiper.autoplay.stop();
      };
      this.pcSwiper.el.onmouseleave = () => {
        this.pcSwiper.autoplay.start();
      };
    },
  },
};
</script>
<style lang="scss">
@import "../../assets/css/swiper-bundle.min.css";

#bannerView {
  width: 100%;
  background-color: #000;
  position: relative;

  .ban_swiper {
    max-width: 1920px;
    width: 100%;
    height: 4.6875rem
      /* 900/192 */
    ;
  }

  /* .swiper-slide {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
} */
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .my-bullet {
    display: inline-block;
    width: 0.34rem;
    height: 0.031rem;
    background: #fff;
    margin-right: 0.175rem;
    border-radius: 0.0625rem;
  }

  .swiper-pagination2 .swiper-pagination-bullet-active {
    background-color: #ff0000 !important;
    width: 0.5rem;
    transition: all 0.3s linear;
  }

  /* .ban_swiper {
  transform: translateY(.1rem);
} */
  .swiper-pagination2 {
    position: absolute;
    z-index: 9;
    bottom: 0.25rem !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
<style scoped lang="scss">
//下载
.toast {
  padding: 0.078125rem
    /* 15/192 */
    0.15625rem
    /* 30/192 */
  ;
  font-size: 0.09375rem
    /* 18/192 */
  ;
  background: rgba(0, 0, 0, 0.7);
  text-align: center;
  border-radius: 0.09375rem
    /* 18/192 */
  ;
  color: #ffffff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999;
}

.download_box {
  width: 100%;
  height: 0.729167rem
    /* 140/192 */
  ;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;

  .ios_box {
    width: 1.770833rem
      /* 340/192 */
    ;
    height: 0.395833rem
      /* 76/192 */
    ;
    background: #cc5341;
    border-radius: 0.052083rem
      /* 10/192 */
    ;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    .ios_img {
      margin-right: 0.083333rem
        /* 16/192 */
      ;

      img {
        display: block;
        width: 0.213542rem
          /* 41/192 */
        ;
        height: 0.260417rem
          /* 50/192 */
        ;
      }
    }

    .ios_text {
      .ios_xz {
        color: #fff;
        font-size: 0.125rem
          /* 24/192 */
        ;
      }

      .ios_jqqd {
        color: #fff;
        font-size: 0.072917rem
          /* 14/192 */
        ;
      }
    }
  }

  .ios_box:hover {
    background: #ec604b;
  }

  .az_box {
    width: 1.770833rem
      /* 340/192 */
    ;
    height: 0.395833rem
      /* 76/192 */
    ;
    background: #cc5341;
    border-radius: 0.052083rem
      /* 10/192 */
    ;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0.651042rem
      /* 125/192 */
    ;
    cursor: pointer;

    .az_img {
      width: 0.239583rem
        /* 46/192 */
      ;
      height: 0.255208rem
        /* 49/192 */
      ;
      margin-right: 0.083333rem
        /* 16/192 */
      ;

      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }

    .az_text {
      color: #fff;
      font-size: 0.125rem;
    }
  }

  .az_box:hover {
    background: #ec604b;
  }
}
</style>
