var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"bannerView"}},[_vm._m(0),_c('div',{staticClass:"download_box"},[_c('div',{staticClass:"ios_box",on:{"click":function($event){return _vm.iosTo()}}},[_vm._m(1),_vm._m(2)]),_c('div',{staticClass:"az_box",on:{"click":function($event){return _vm.azTo()}}},[_vm._m(3),_c('div',{staticClass:"az_text"},[_vm._v("下载Android版本")])]),_c('div',{staticClass:"ios_box",on:{"click":_vm.PCTo}},[_vm._m(4),_vm._m(5)])]),(_vm.isToast)?_c('div',{staticClass:"toast"},[_vm._v("即将震撼来袭 敬请期待")]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"swiper ban_swiper"},[_c('div',{staticClass:"swiper-wrapper"},[_c('div',{staticClass:"swiper-slide"},[_c('a',{attrs:{"href":"https://mp.weixin.qq.com/s/YsC5VD85uoDB0tkt6FSF2w","target":"_blank"}},[_c('img',{attrs:{"src":require("../../assets/image/pc/banner/121.jpg"),"alt":""}})])]),_c('div',{staticClass:"swiper-slide"},[_c('a',{attrs:{"href":"https://mp.weixin.qq.com/s/gdfdAOYLr-Z6jHe1M6K6Pg","target":"_blank"}},[_c('img',{attrs:{"src":require("../../assets/image/pc/banner/hzb.png"),"alt":""}})])]),_c('div',{staticClass:"swiper-slide"},[_c('a',{attrs:{"href":"https://mp.weixin.qq.com/s/Gz53m3v4qcSBVigunkGpYA","target":"_blank"}},[_c('img',{attrs:{"src":require("../../assets/image/pc/banner/hz555.jpg"),"alt":""}})])]),_c('div',{staticClass:"swiper-slide"},[_c('a',{attrs:{"href":"https://mp.weixin.qq.com/s/abzYupDefKAkLi9oBlRZtw","target":"_blank"}},[_c('img',{attrs:{"src":require("../../assets/image/pc/banner/new19.jpg"),"alt":""}})])]),_c('div',{staticClass:"swiper-slide"},[_c('a',{attrs:{"href":"https://mp.weixin.qq.com/s/SfqiCnSMZ8mvNaXA3P6_Mw","target":"_blank"}},[_c('img',{attrs:{"src":require("../../assets/image/pc/banner/1897.jpg"),"alt":""}})])]),_c('div',{staticClass:"swiper-slide"},[_c('a',{attrs:{"href":"https://mp.weixin.qq.com/s/l1F93_MoYNPWv4o-evaeJA","target":"_blank"}},[_c('img',{attrs:{"src":require("../../assets/image/pc/banner/banner11.jpg"),"alt":""}})])])]),_c('div',{staticClass:"swiper-pagination2"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ios_img"},[_c('img',{attrs:{"src":require("../../assets/image/icon/16.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ios_text"},[_c('p',{staticClass:"ios_xz"},[_vm._v("下载iOS版本")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"az_img"},[_c('img',{attrs:{"src":require("../../assets/image/icon/17.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ios_img"},[_c('img',{staticStyle:{"width":"0.2rem /* 41/192 */","height":"0.2rem"},attrs:{"src":require("../../assets/image/icon/pc.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ios_text"},[_c('p',{staticClass:"ios_xz"},[_vm._v("下载电脑版本")])])
}]

export { render, staticRenderFns }