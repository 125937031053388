<template>
  <div class="wap_index3 main">
    <div class="box_center wap_index3-center">
      <div class="wap_index3-title">
        <div>商务合作</div>
      </div>
      <div class="wap_game_single_nav" ref="wap_game_single_nav">
        <div class="wap_game_single_nav_ul">
          <div @click="wapgameSingle(index)" v-for="(item, index) in list" :key="index"
            :class="wapgameSingindex == index ? 'nav_ul_activate' : ''">
            {{ item.title }}
          </div>
          <div class="wap_game_single_wire"></div>
        </div>
      </div>
      <div class="new_wap_index3-ul">
        <template v-if="wapgameSingindex == 0">
          <div class="wapgameSingindex_0">
            <div class="li-card">
              <div class="li-card-name">QQ邮箱</div>
              <div class="li-card-flex">
                <div class="business3 li-card-icon"></div>
                <div>hanbin@qianyouqn.com</div>
              </div>
            </div>
          </div>
        </template>
        <template v-else-if="wapgameSingindex == 1">
          <div class="wapgameSingindex_0" v-for="(item, index) in listCard" :key="index">
            <div class="li-card" v-for="(row, rowindex) in item.arr" :key="rowindex">
              <div class="li-card-name">{{ row.name }}</div>
              <div class="li-card-flex">
                <div class="business1 li-card-icon"></div>
                <div>{{ row.qq }}</div>
              </div>
              <div class="li-card-flex jg">
                <div class="business2 li-card-icon"></div>
                <div>{{ row.phone }}</div>
              </div>
              <div class="li-card-flex">
                <div class="business3 li-card-icon"></div>
                <div>{{ row.mailbox }}</div>
              </div>
            </div>
          </div>
        </template>
        <template v-else-if="wapgameSingindex == 2">
          <div class="wapgameSingindex_2" style="text-align: center">
            <div class="li-card-qrcode">
              <img src="../../assets/image/pc/aboutus/gzh.jpg" alt="" />
            </div>
            <div style="
                color: #000;
                font-size: 0.48rem /* 18/37.5 */;
                margin-top: 0.266667rem; /* 10/37.5 */
              ">
              长按保存 扫码关注
            </div>
          </div>
        </template>
      </div>
    </div>
    <div v-if="viewportHeight" class="wap_index3_orientation"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      viewportHeight: true,
      list: [
        { title: "商务合作" },
        { title: "千年引擎合作" },
        { title: "微信公众号" },
      ],
      listCard: [
        {
          arr: [
            {
              name: "千游商务小五",
              qq: "594241",
              phone: "15010805655",
              mailbox: "hanbin@qianyouqn.com",
            },
            // {
            //   name: "千游商务阿成",
            //   qq: "649265011",
            //   phone: "18576468073",
            //   mailbox: "shenzhuocheng@qianyouqn.com",
            // },
            {
              name: "千游商务豪杰",
              qq: "594843422",
              phone: "19852886295",
              mailbox: "shihaojie@qianyouqn.com",
            },
          ],
        },
        {
          arr: [
            {
              name: "千游商务阿成",
              qq: "649265011",
              phone: "18576468073",
              mailbox: "shenzhuocheng@qianyouqn.com",
            },
            {
              name: "千游商务小杰",
              qq: "1147125160",
              phone: "19235009077",
              mailbox: "wangyoujie@qianyouqn.com",
            },
          ],
        },
      ],
      wapgameSingindex: 0,
    };
  },
  created() { },
  mounted() { },
  methods: {
    wapgameSingle(index) {
      let special = document.querySelector(".wap_game_single_wire");
      let navWidth = document.querySelector(
        ".wap_game_single_wire"
      ).clientWidth;
      let liWidth = document.querySelector(".wap_game_single_nav_ul").children[
        index
      ].clientWidth;
      let left =
        document.querySelector(".wap_game_single_nav_ul").children[index]
          .offsetLeft -
        (navWidth - liWidth) / 2;
      // if (index >= 2) {
      //   this.$refs.wap_game_single_nav.scrollLeft = index * 80;
      // } else {
      //   this.$refs.wap_game_single_nav.scrollLeft = 0;
      // }
      special.style.transform = `translateX(${left}px)`;
      this.wapgameSingindex = index;
      this.wapgameSinglelist[index].show = true;
    },
  },
};
</script>

<style scoped lang="scss">
.wap_game_single_nav::-webkit-scrollbar {
  background-color: transparent;
}

.wap_game_single_nav {
  width: 100%;
  overflow-x: auto;
  position: relative;
  padding-left: 0.533333rem
    /* 20/37.5 */
  ;

  .wap_game_single_nav_ul {
    display: flex;
    width: 120%;
    padding-bottom: 0.266667rem
      /* 10/37.5 */
    ;
    border-bottom: 0.053333rem
      /* 2/37.5 */
      solid #707070;
    position: relative;

    >div {
      flex-shrink: 0;
      color: #000;
      margin-right: 0.773333rem
        /* 29/37.5 */
      ;
      font-size: 0.373333rem
        /* 14/37.5 */
      ;
    }

    .nav_ul_activate {
      color: #ff0e0e;
    }
  }

  .wap_game_single_wire {
    position: absolute;
    left: 0;
    bottom: -0.026667rem
      /* 1/37.5 */
    ;
    width: 1.813333rem
      /* 68/37.5 */
    ;
    height: 0.053333rem
      /* 2/37.5 */
    ;
    background: #ff0e0e;
    transform: translateX(0);
    transition: transform 500ms cubic-bezier(0.48, 0.33, 0.24, 0.95) 0ms;
  }
}

.new_wap_index3-ul::-webkit-scrollbar {
  background-color: transparent;
}

.new_wap_index3-ul {
  width: 100%;
  overflow-x: auto;

  // margin:0 .533333rem /* 20/37.5 */;
  .wapgameSingindex_0 {
    display: flex;
    align-items: center;
    margin-bottom: 1.066667rem
      /* 40/37.5 */
    ;

    .li-card {
      flex-shrink: 0;
      width: 7rem
        /* 230/37.5 */
      ;
      margin-right: 0.533333rem
        /* 20/37.5 */
      ;
      font-size: 0.373333rem
        /* 14/37.5 */
      ;
      color: #000;
      background: #fff;
      border-radius: 0.25rem;
      padding: 0.266667rem
        /* 10/37.5 */
      ;

      .li-card-name {
        color: #000;
        font-weight: bold;
        margin-bottom: 0.533333rem
          /* 20/37.5 */
        ;
      }

      .jg {
        padding: 0.533333rem
          /* 20/37.5 */
          0;
      }

      .li-card-flex {
        display: flex;
        align-items: center;

        .li-card-icon {
          flex-shrink: 0;
          width: 0.533333rem
            /* 20/37.5 */
          ;
          height: 0.533333rem
            /* 20/37.5 */
          ;
          background-size: contain;
          margin-right: 0.266667rem
            /* 10/37.5 */
          ;
        }
      }

      .business1 {
        background: url("../../assets/image/icon/3.png") no-repeat center;
      }

      .business2 {
        background: url("../../assets/image/icon/12.png") no-repeat center;
      }

      .business3 {
        background: url("../../assets/image/wap/icon/15.png") no-repeat center;
      }
    }
  }

  .wapgameSingindex_2 {
    text-align: center;

    .li-card-qrcode {
      margin: 0 auto;
      height: 8rem
        /* 300/37.5 */
      ;
      width: 8rem
        /* 300/37.5 */
      ;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.main {
  width: 100%;
  height: 100%;
}

.wap_index3 {
  background: url("../../assets/image/wap/bg2/5.png") no-repeat center;
  background-size: cover;
  position: relative;
}

.wap_index3-center {
  position: relative;
  z-index: 1;
  margin: 0 0.533333rem;
}

.wap_index3_orientation {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  // background: url("../../assets/image/pc/aboutus/qq.png") no-repeat center;
  background-size: cover;
  width: 8.533333rem
    /* 320/37.5 */
  ;
  height: 6.133333rem
    /* 230/37.5 */
  ;
}

.wap_index3-title {
  padding-top: 1.83333rem !important;
  padding-bottom: 0.533333rem
    /* 20/37.5 */
  ;
  text-align: center;
}

.wap_index3-title>div:nth-child(1) {
  font-size: 0.533333rem
    /* 20/37.5 */
  ;
  color: #000;
  font-weight: bold;
}

.wap_index3-title>div:nth-child(2) {
  font-size: 0.32rem
    /* 12/37.5 */
  ;
  color: #707070;
}

.wap_index3-ul_li {
  padding: 0.48rem
    /* 18/37.5 */
  ;
  min-height: 4.586667rem
    /* 172/37.5 */
  ;
  border-radius: 0.4rem
    /* 15/37.5 */
  ;
  box-sizing: border-box;
  margin-bottom: 0.426667rem
    /* 16/37.5 */
  ;
  border: 1px solid #5c5c5c;
}

.wap_index3-ul_li .wap_index3-ul_li1 {
  display: flex;
  color: #000 !important;
  -webkit-text-fill-color: #000;
  font-size: 0.373333rem
    /* 14/37.5 */
  ;
  align-items: center;
  padding-bottom: 0.48rem
    /* 18/37.5 */
  ;
}

.wap_index3-ul_li .wap_index3-ul_li1>div:nth-child(1) {
  width: 0.64rem
    /* 24/37.5 */
  ;
  display: flex;
  justify-content: center;
  margin-right: 0.373333rem
    /* 14/37.5 */
  ;
}

.wap_index3-ul_li .wap_index3-ul_li1 .business1 {
  width: 0.533333rem
    /* 20/37.5 */
  ;
  height: 0.533333rem
    /* 20/37.5 */
  ;
  // background: url("../../assets/image/pc/aboutus/tel.png") no-repeat center;
  background-size: cover;
}

.wap_index3-ul_li .wap_index3-ul_li1 .business2 {
  width: 0.533333rem
    /* 20/37.5 */
  ;
  height: 0.533333rem
    /* 20/37.5 */
  ;
  // background: url("../../assets/image/wap/icon/15.png") no-repeat center;
  background-size: contain;
}

.wap_index3-ul_li .wap_index3-ul_li1 .business3 {
  width: 0.533333rem
    /* 20/37.5 */
  ;
  height: 0.533333rem
    /* 20/37.5 */
  ;
  // background: url("../../assets/image/pc/aboutus/qq.png") no-repeat center;
  background-size: cover;
}

.wap_index3-ul_li .wap_index3-ul_li1 .business4 {
  width: 0.64rem
    /* 24/37.5 */
  ;
  height: 0.533333rem
    /* 20/37.5 */
  ;
  // background: url("../../assets/image/pc/aboutus/qq.png") no-repeat center;
  background-size: cover;
}

@media (max-width: 750px) and (max-height: 667px) {
  .wap_index3_orientation {
    display: none;
  }

  .wap_index3-title {
    padding-top: 1.466667rem
      /* 55/37.5 */
    ;
    padding-bottom: 0.4rem
      /* 15/37.5 */
    ;
  }
}
</style>
